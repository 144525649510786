import React from 'react'
import Pagebuilder from '../pagebuilder/Pagebuilder'

const Page = ({ title, _rawPagebuilder }) => {
  return (
    <div className="Page">
      <header className="Page__header">
        <h1 className="Page__title">{title}</h1>
      </header>
      {_rawPagebuilder?.sections && (
        <Pagebuilder sections={_rawPagebuilder.sections} />
      )}
    </div>
  )
}

export default Page
